(function ($) {
    var xhr = null;
    var xhrAddress = null;
    var xhrPrice = null;
    var xhrPvz = null;
    var timer = null;
    var blurTimer = {};
    var fiasRegexp = /^[a-f0-9\-]{36}$/;
    var zipRegexp = /^\d{6}$/;
    var phoneRegexp = /^(\+?\d)?\(?[0-9]{3}\)?([-\s]?[0-9]{3})([-\s]?[0-9]{2}){2}$/im;
    var emailRegexp = /^[a-z0-9\._+$&\-]+@([a-z0-9\-]+\.){1,}([a-z]{2,16})$/im;

    //#region Load Functions
    function geocode(str, kind, locations, cb) {
        if (xhr && xhr.readyState != 4) {
            xhr.abort();
        }

        xhr = $.ajax({
            method: 'get',
            url: "/geo",
            dataType: "json",
            data: {
                value: str,
                kind: kind,
                city: locations ? locations.cityId : undefined,
                settlement: locations && !locations.cityId ? locations.settlementId : undefined,
                street: locations ? locations.streetId : undefined,
            },
            success: function (response) {
                cb(response)
            }
        });
    }

    function loadClarifiedAddress(str, settlement_fias_id, city_fias_id, cb) {
        if (xhrAddress && xhrAddress.readyState != 4) {
            xhrAddress.abort();
        }

        xhrAddress = $.ajax({
            method: 'get',
            url: "/geo/address",
            dataType: "json",
            data: {
                query: str,
                fias_id: settlement_fias_id ? settlement_fias_id : city_fias_id
            },
            success: function (response) {
                cb(null, response)
            },
            error: function (jqXHR, exception) {
                cb(exception);
            }
        });
    }

    function loadDeliveryMethods(basketEncoded, city_fias_id, settlement_fias_id, postcode, cb) {
        if (xhrPrice && xhrPrice.readyState != 4) {
            xhrPrice.abort();
        }

        xhrPrice = $.ajax({
            method: 'get',
            url: "/geo/cdek",
            dataType: "json",
            data: {
                order: basketEncoded,
                city_fias_id: city_fias_id,
                settlement_fias_id: settlement_fias_id,
                postcode: postcode
            },
            success: function (response) {
                cb(null, response)
            },
            error: function (jqXHR, exception) {
                cb(exception);
            }
        });
    }

    function loadPvz(service, city, cb) {
        if (xhrPvz && xhrPvz.readyState != 4) {
            xhrPvz.abort();
        }

        xhrPvz = $.ajax({
            method: 'get',
            url: "/geo/pvz",
            dataType: "json",
            data: {
                service: service,
                city: city
            },
            success: function (response) {
                cb(null, response);
            },
            error: function (jqXHR, exception) {
                if(jqXHR.status != 0) {
                    cb(exception);
                }
            }
        });
    }

    function removeProductReq(productId, cb) {
        $.ajax({
            method: 'delete',
            url: "/basket/" + productId,
            dataType: "json",
            success: function (response) {
                cb(null, response);
            },
            error: function (jqXHR, exception) {
                cb(exception);
            }
        });
    }
    //#endregion

    //#region Suggestion fields

    function onSelectHandler(e) {
        var $el = $(this);
        var $input = $el.parent().find('input');
        var $a = $(e.target).closest('a');

        if ($a.data('kind') === 'house') {
            fillAddress($input, $a, 'curlevel');
        } else if ($a.data('kind') === 'street') {
            fillAddress($input, $a, 'curlevel');
            fillAddress($('#inputCity'), $a, 'toplevel');
        } else {
            fillAddress($input, $a);
        }

        var $wrap = $a.closest('.autofill-wrap');
        $wrap.removeClass('suggestion');
        $wrap.html('');

        e.preventDefault();
        e.stopPropagation();

        return false;
    }

    /**
     * Mouse down on suggestion
     */
    function onMouseDownHandler(e) {
        var $el = $(this);
        var $input = $el.closest('.form-group').find('input');
        var $a = $(e.target).closest('a');

        if (blurTimer[$input.attr('id')]) {
            clearTimeout(blurTimer[$input.attr('id')]);
            blurTimer[$input.attr('id')] = null;
        }

        if ($a.data('auto') != '1') {
            !isMobile.any && setTimeout(function () {
                $input.focus();
            }, 10);
        }

        if(isMobile.any) $input.blur();

        e.stopPropagation();
        e.preventDefault();
        return false;
    }

    /**
     * Focus on input for suggestion
     */
    function onFocusHandler() {
        var $input = $(this); // input
        var $values = $input.parent().find('.autofill-wrap'); // suggestion

        if (xhr && xhr.readyState != 4) {
            xhr.abort();
        }

        if (blurTimer[$input.attr('id')]) {
            clearTimeout(blurTimer[$input.attr('id')]);
            blurTimer[$input.attr('id')] = null;
        } else {
            if ($input.data('init') != '1') {
                $values.on('mousedown', onMouseDownHandler).on('click', onSelectHandler);
                $input.on('keyup', onChangeHandler).data('init', '1');
                onChangeHandler.call(this);
            }
        }
    }

    /**
     * Blur on input for suggestion
     */
    function onBlurHandler() {
        var $input = $(this);
        var $values = $input.parent().find('.autofill-wrap');

        blurTimer[$input.attr('id')] = setTimeout(function () {
            blurTimer[$input.attr('id')] = null;

            if (!$input.data('complete')) {
                // Для незавершённого елемента завершаем
                // автоматически по первому совпадению
                var $suggestion = $values.find('a');

                if ($input.data('autocomplete') && $suggestion.length > 0) {
                    $suggestion.eq(0).data('auto', '1');
                    $suggestion.eq(0).click();
                } else {
                    fillAddress($input);
                }
            }

            $values.html('')
                .removeClass('suggestion')
                .off('mousedown', onMouseDownHandler)
                .off('click', onSelectHandler);

            $input.off('keyup', onChangeHandler).data('init', '0');
        }, 100);

        if (timer) {
            clearTimeout(timer);
            timer = null;
        }

        if (xhr && xhr.readyState != 4) {
            xhr.abort();
        }
    }

    function onChangeHandler() {
        var $input = $(this);
        var kinds = ($input.data('kind') || '').split(',');
        var val = $input.val().trim();

        if (timer) {
            clearTimeout(timer);
            timer = null;
        }

        if ($input.data('complete') !== val) { // Отмечаем как не завершённое
            $input.data('complete', '');
            fillAddress($input);
        }

        if (val.length >= 1) {

            timer = setTimeout(function () {

                var bounds = ($input.data('kind-bounds') || '').split(',');
                var $form = $input.closest('form');
                var reqBounds = {};

                for (var i = 0; i < bounds.length; i++) {
                    if ($form.data(bounds[i] + 'Id')) {
                        reqBounds[bounds[i] + 'Id'] = $form.data(bounds[i] + 'Id');
                    }
                }

                geocode($input.val(), kinds[kinds.length - 1], reqBounds, function (data) {

                    var $wrap = $input.parent();
                    var $values = $wrap.find('.autofill-wrap');

                    $values.html('');

                    if (data.length > 0) {
                        var exists = false;

                        for (var i = 0; i < data.length; i++) {
                            var v = data[i].customValue.replace('Россия, ', '');

                            if (kinds[0] === 'street') {
                                v = data[i].currentLevel + '<small>' + data[i].topLevelValue.replace('Россия, ', '') + '</small>';
                            }

                            $values.append(
                                '<a href"#" ' +
                                'data-kind="' + kinds[0] + '" ' +
                                'data-city-id="' + data[i].data.city_fias_id + '" ' +
                                'data-city="' + data[i].data.city + '" ' +
                                'data-settlement-id="' + data[i].data.settlement_fias_id + '" ' +
                                'data-settlement="' + data[i].data.settlement + '" ' +
                                'data-street-id="' + data[i].data.street_fias_id + '" ' +
                                'data-street="' + data[i].data.street + '" ' +
                                'data-house-id="' + data[i].data.house_fias_id + '" ' +
                                'data-house="' + data[i].data.house + '" ' +
                                'data-postal-code="' + data[i].data.postal_code + '" ' +
                                'data-flat="' + data[i].data.flat + '" ' +
                                'data-toplevel="' + data[i].topLevelValue.replace('Россия, ', '') + '" ' +
                                'data-curlevel="' + data[i].currentLevel + '" ' +
                                '>' + v + '</a>'
                            );

                            exists = true;
                        }

                        if (exists) $values.addClass('suggestion');
                        else $values.removeClass('suggestion');
                    } else {
                        $values.removeClass('suggestion');
                    }
                });
            }, 100);
        } else {
            var $wrap = $input.parent();
            var $values = $wrap.find('.autofill-wrap');
            $values.removeClass('suggestion');
            $values.html('');
        }
    }

    function fillAddress($input, $item, key) {
        var $form = $input.closest('form');
        var kinds = ($input.data('kind') || '').split(',');
        var bounds = ($input.data('kind-bounds') || '').split(',');
        var otherKeys = ($input.data('other-keys') || '').split(',');
        var eventName = $input.data('event');
        var textValue = $input.val().trim();

        if (kinds.length === 0) return;

        if (typeof $item === 'string') {
            textValue = $item;
            $item = null;
        } else if ($item) {
            if (key)
                textValue = $item.data(key);
            else
                textValue = $item.text();
        }

        var kindIsset = null;

        for (var i = 0; i < kinds.length; i++) {
            var curKindValue = $item ? $item.data(kinds[i] + 'Id') : null;

            if (curKindValue && !kindIsset) {
                $form.data(eventName + 'Value', curKindValue);
                $form.data(eventName + 'Kind', kinds[i]);
                kindIsset = kinds[i];
            }

            if ($item) {
                $input.data(kinds[i], $item.data(kinds[i]));
                $input.data(kinds[i] + 'Id', $item.data(kinds[i] + 'Id'));
                $form.data(kinds[i], $item.data(kinds[i]));
                $form.data(kinds[i] + 'Id', $item.data(kinds[i] + 'Id'));
            } else {
                $input.data(kinds[i], '');
                $input.data(kinds[i] + 'Id', '');
                $form.data(kinds[i], '');
                $form.data(kinds[i] + 'Id', '');
            }
        }

        for (var i = 0; i < bounds.length; i++) {
            if ($item) {
                $input.data(bounds[i], $item.data(bounds[i]));
                $input.data(bounds[i] + 'Id', $item.data(bounds[i] + 'Id'));
            } else {
                $input.data(bounds[i], '');
                $input.data(bounds[i] + 'Id', '');
            }
        }

        for (var i = 0; i < otherKeys.length; i++) {
            if ($item) {
                $input.data(otherKeys[i], $item.data(otherKeys[i]));
            } else {
                $input.data(otherKeys[i], '');
            }
        }

        if (kindIsset) {
            $input.data('complete', textValue);
        } else {
            $form.data(eventName + 'Value', '');
            $form.data(eventName + 'Kind', '');
            $input.data('complete', '');
        }

        if ($input.val() !== textValue)
            $input.val(textValue);

        setTimeout(function () {
            $form.trigger($input.data('event'), kindIsset ? [kindIsset, $input.data(kindIsset + 'Id')] : []);
        }, 10);
    }

    //#endregion

    //#region Кастомный селект для ПВЗ

    var customSelectTimer = null;

    function onClickDeliveryMethod() { // Click on delivery method
        var $el = $(this); // input
        var $form = $el.closest('form');
        var $sel = $form.find('input[type="radio"][name="delivery"]:checked');
        var $holder = $('.pvz-input-holder');
        var $values = $holder.find('.autofill-wrap');

        var pvzNeed = $sel.data('pvzNeed');
        var flatNeed = $sel.data('flatNeed');
        var price = parseInt($sel.data('price'));
        var service = $sel.data('service');
        var city = parseInt($sel.data('to'));

        calcPrice();

        $form.find('input[type="radio"][name="delivery"]').each(function () {
            $(this).data('lastchecked', $(this).prop('checked'));
        });

        validate();
        applyDeliveryMethod();
    }

    function customSelectMouseDown(e) {
        var $el = $(this);

        if (customSelectTimer) {
            clearTimeout(customSelectTimer);
            customSelectTimer = null;
        }

        if(!isMobile.any) {
            setTimeout(function () {
                $el.parent().find('input').focus();
            }, 10);
        } else {
            $el.parent().find('input').blur();
        }

        e.stopPropagation();
        e.preventDefault();
        return false;
    }

    function customSelectMouseClick(e) {
        var $el = $(this);
        var $input = $el.closest('.form-group').find('input[type="text"]');
        var $a = $(e.target).closest('a');
        var bind = ($input.data('bind') || '').split(',');

        if ($a.length) {
            $input.data('complete', $a.data('value')).val($a.data('value'));

            for(var i = 0; i < bind.length; i++) {
                var keys = bind[i].split(':');

                if(keys.length === 2) {
                    $input.closest('form').find('input[name="' + keys[1] + '"]')
                        .val($a.data(keys[0]));
                }
            }

            if($input.data("event"))
                $input.closest('form').trigger($input.data("event"));

            var $wrap = $a.closest('.autofill-wrap');
            $wrap.removeClass('suggestion');
        }

        e.stopPropagation();
        e.preventDefault();
        return false;
    }

    function customSelectChange() {
        var $input = $(this);

        $input.parent().find('.autofill-wrap a').each(function () {
            var $a = $(this);
            if ($a.text().toLowerCase().trim().indexOf($input.val().toLowerCase()) > -1) $a.show();
            else $a.hide();
        });

        if($input.val().trim() !== $input.data('complete')) {
            $input.data('complete', '');
            var bind = ($input.data('bind') || '').split(',');

            for(var i = 0; i < bind.length; i++) {
                var keys = bind[i].split(':');

                if(keys.length === 2) {
                    $input.closest('form').find('input[name="' + keys[1] + '"]').val('');
                }
            }

            $input.parent().find('.autofill-wrap').addClass('suggestion');
        }

        if($input.data("event"))
            $input.closest('form').trigger($input.data("event"));
    }

    function customSelectFocus() {
        var $el = $(this);

        if (customSelectTimer) {
            clearTimeout(customSelectTimer);
            customSelectTimer = null;
        } else {
            var $wrap = $el.parent().find('.autofill-wrap');

            if ($el.data('init') != '1') {
                $wrap.addClass('suggestion')
                $wrap.on('mousedown', customSelectMouseDown);
                $wrap.on('click', customSelectMouseClick);
                $el.on('keyup', customSelectChange);
                $el.data('init', '1');
            }
        }
    }

    function customSelectBlur() {
        var $el = $(this);

        customSelectTimer = setTimeout(function () {
            customSelectTimer = null;
            $el.closest('form').find('.autofill-wrap')
                .removeClass('suggestion')
                .off('mousedown', customSelectMouseDown)
                .off('click', customSelectMouseClick);
            $el.off('keyup', customSelectChange);
            $el.closest('form').find('.autofill-wrap a').show();

            if($el.data("event"))
                $el.closest('form').trigger($el.data("event"));

            $el.data('init', '0');
        }, 100)
    }

    //#endregion

    //#region Функции управления формой

    /**
     * Пересчитывает и отображает стоимость в корзине
     */
    function calcPrice() {

    }

    /**
     * Загружает и показывает методы доставки
     */
    function showDelivery(force) {
        var $city = $('#inputCity');
        var $form = $city.closest('form');
        var $holder = $form.find('.delivery-list-holder');
        var $list = $holder.find('.delivery-list');
        var fiasId = null;
        var restoreSelected = null;

        if ($city.val().trim()) {
            if (fiasRegexp.test($city.data('settlementId'))) fiasId = $city.data('settlementId');
            else if (fiasRegexp.test($city.data('cityId'))) fiasId = $city.data('cityId');
        }

        if (xhrPrice && xhrPrice.readyState != 4) {
            xhrPrice.abort();
            xhrPrice = null;
        }

        $holder.addClass('loading').removeClass('d-none server-error'); // Show delivery block as loading
        $list.addClass('d-none'); // Hide delivery methods list

        // Город не выбран
        if (!fiasRegexp.test(fiasId)) {
            $list.find('input[type="radio"][name="delivery"]:checked').prop('checked', false);
            $holder.removeClass('loading').addClass('d-none'); // Hide delivery block
            validate();
            applyDeliveryMethod();
            return;
        }

        // Уже загружен актуальный список доставок для данного города
        if (!force && $holder.data('delivery-for') === fiasId) {
            $holder.removeClass('loading'); // Hide loading animation
            $list.removeClass('d-none').find('input[type="radio"][name="delivery"]').each(function () {
                if ($(this).data('lastchecked')) {
                    $(this).prop('checked', true);
                    onClickDeliveryMethod.call(this);
                }
            });
            validate();
            applyDeliveryMethod();
            return;
        } else if(force) {
            $list.find('input[type="radio"][name="delivery"]').each(function () {
                if ($(this).data('lastchecked')) {
                    restoreSelected = $(this).attr('id');
                }
            });
        }

        loadDeliveryMethods(
            $form.find('input[name="order"]').val(),
            $city.data('cityId'),
            $city.data('settlementId'),
            $city.data('postalCode'),
            function (err, data) {
                $holder.removeClass('loading server-error'); // Hide loading animation

                if (err) {
                    $holder.addClass('server-error').data('delivery-for', '');
                    applyDeliveryMethod();
                    return;
                }

                $list.removeClass('d-none').html('');

                for (var i = 0; i < data.length; i++) {
                    var id = [data[i].service, data[i].tariffId].join('_');
                    $list.append(
                        '<div class="form-check">'
                        + '<input class="form-check-input" type="radio" name="delivery" id="' + id + '" value="' + data[i].encoded + '"'
                        + 'data-price="' + data[i].price + '" '
                        + 'data-pvz-need="' + (data[i].pvzRequired ? '1' : '0') + '" '
                        + 'data-flat-need="' + (data[i].flatRequired ? '1' : '0') + '" '
                        + 'data-service="' + data[i].service + '" '
                        + 'data-to="' + data[i].to + '" '
                        + (restoreSelected === id ? 'checked ': '')
                        + '>'
                        + '<label class="form-check-label" for="' + id + '">'
                        + [
                            data[i].name,
                            data[i].period,
                            data[i].price ? data[i].price + ' руб.' : 'Бесплатно'
                        ].filter(function (v) { return !!v; }).join(', ')
                        + '<small>' + data[i].type + '</small>'
                        + '</label>'
                        + '</div>'
                    );
                }

                $list.find('input[type="radio"][name="delivery"]').on('change', onClickDeliveryMethod);
                $holder.data('delivery-for', fiasId);
                applyDeliveryMethod();
                validate();
            }
        );
    }

    /**
     * Скрывает относящююся к доставке форму
     */
    function hideDelivery() {
        if (xhrPrice && xhrPrice.readyState != 4) {
            xhrPrice.abort();
            xhrPrice = null;
        }

        if (xhrAddress && xhrAddress.readyState != 4) {
            xhrAddress.abort();
            xhrAddress = null;
        }

        if (xhrPvz && xhrPvz.readyState != 4) {
            xhrPvz.abort();
            xhrPvz = null;
        }

        $('.delivery-list-holder').addClass('d-none');
        $('.pvz-input-holder').addClass('d-none');
        $('.address-input-holder').addClass('d-none');
    }

    /**
     * Применяет выбранный метод доставки
     */
    function applyDeliveryMethod() {
        var $form = $('#orderForm');
        var $city = $('#inputCity');
        var $street = $('#inputStreet');
        var $house = $('#inputHouse');
        var $flat = $('#inputApt');
        var $postalCode = $('#inputPostalCode');
        var $pvzHolder = $form.find('.pvz-input-holder');
        var $pvzValues = $pvzHolder.find('.autofill-wrap');
        var $sel = $form.find('input[type="radio"][name="delivery"]:checked');

        var service = $sel.data('service');
        var city = $sel.data('to');
        var pvzNeed = $sel.data('pvz-need') == '1';
        var flatNeed = $sel.data('flat-need') == '1';
        var price = parseInt($sel.data('price'));

        $pvzHolder.addClass('d-none');
        $form.find('.address-input-holder').addClass('d-none');

        validate();

        if (!service || !city) {
            updateBasketPrice();
            return false;
        }

        updateBasketPrice(price);

        if (flatNeed) { // Для данного способа требуется полный адрес
            $pvzHolder.addClass('d-none')
            $form.find('.address-input-holder').removeClass('d-none')
            $form.find('#inputPvz').val('').attr('placeholder', '');
            $pvzValues.html('');

            if(($street.data('cityId') && $street.data('cityId') !== $city.data('cityId'))
            || ($street.data('settlementId') && $street.data('settlementId') !== $city.data('settlementId'))) {
                // Улица заполнялась автоматически, но не соответсвует городу, чистим форму
                fillAddress($street, '');
                fillAddress($house, '');
                $flat.val('');
                $postalCode.val('');
            }

            fillPostalCode();
        } else if (pvzNeed) { // Для данного способа требуется выбрать ПВЗ
            $form.find('.address-input-holder').addClass('d-none');
            $pvzHolder.addClass('loading').removeClass('d-none server-error');

            if (xhrPvz && xhrPvz.readyState != 4) {
                xhrPvz.abort();
                xhrPvz = null;
            }

            loadPvz(service, city, function (err, data) {
                $pvzValues.html('');
                $pvzHolder.removeClass('loading');

                if(err) {
                    $pvzHolder.addClass('server-error');
                    return;
                }

                for (var i = 0; i < data.length; i++) {
                    if(!data[i].address) continue;

                    var address = data[i].address;

                    if (data[i].metroStation) {
                        address += '<small>' + data[i].metroStation + '</small>'
                    }

                    $pvzValues.append(
                        '<a href"#" ' +
                        'data-encoded="' + data[i].encoded + '" ' + 
                        'data-value="' + data[i].address.trim() + '" ' +
                        '>' + address + '</a>'
                    );
                }

                $form.find('#inputPvz').attr('placeholder', data.length + ' ПВЗ, начните вводить метро или улицу');
                validate();
            });
        }

        validate();
    }

    /**
     * Автоматически заполняет почтовый индекс
     * по выбранным городу, улице, дому
     */
    function fillPostalCode() {
        var $city = $('#inputCity');
        var $street = $('#inputStreet');
        var $house = $('#inputHouse');
        var $postalCode = $('#inputPostalCode');

        var postalCode = zipRegexp.test($postalCode.val().trim()) ? $postalCode.val().trim() : null;
        var housePostalCode = zipRegexp.test($house.data('postalCode')) ? $house.data('postalCode').toString() : null;
        var streetPostalCode = zipRegexp.test($street.data('postalCode')) ? $street.data('postalCode').toString() : null;
        var cityPostalCode = zipRegexp.test($city.data('postalCode')) ? $city.data('postalCode').toString() : null;

        if(!postalCode) {
            if(housePostalCode) {
                $postalCode.val(housePostalCode);
            } else if(streetPostalCode) {
                $postalCode.val(streetPostalCode);
            } else if(cityPostalCode) {
                $postalCode.val(cityPostalCode);
            }
        } else {
            if(housePostalCode) {
                if(postalCode.substr(0, 3) !== housePostalCode.substr(0, 3)
                    || postalCode.substr(3, 3) === '000') {
                    $postalCode.val(housePostalCode);
                }
            }
        }


    }

    /**
     * Шаг стандартизации адреса и поиска почтового индекса
     * Далее вызывает showDelivery
     */
    function clarifyCity() {
        var $city = $('#inputCity');
        var $form = $city.closest('form');
        var $holder = $form.find('.delivery-list-holder');

        if (xhrAddress && xhrAddress.readyState != 4) {
            xhrAddress.abort();
            xhrAddress = null;
        }

        $holder.removeClass('d-none server-error').addClass('loading'); // Show delivery block as loading

        loadClarifiedAddress($city.val().trim(), $city.data('settlementId'), $city.data('cityId'), function (err, data) {
            $holder.removeClass('loading'); // Hide loading animation

            if (err) {
                $holder.addClass('server-error'); // Show error
            } else {
                if (data.postal_code && zipRegexp.test(data.postal_code)) {
                    $city.data('postalCode', data.postal_code); // Save postal code
                }
                showDelivery();
            }
        });

        $('.pvz-input-holder').addClass('d-none'); // Hide other address fields
        $('.address-input-holder').addClass('d-none'); // Hide other address fields
    }

    /**
     * Валидация и подсветка неправильных полей
     */
    function validate() {
        var $form = $('#orderForm');
        var $city = $('#inputCity');
        var $pvz = $('#inputPvz');
        var $street = $('#inputStreet');
        var $house = $('#inputHouse');
        var $postalCode = $('#inputPostalCode');
        var $phone = $('#inputPhone');
        var $email = $('#inputEmail');
        var $delivery = $form.find('.delivery-list');
        var $sel = $delivery.find('input[type="radio"][name="delivery"]:checked');

        var pvzNeed = $sel.data('pvz-need') == '1';
        var flatNeed = $sel.data('flat-need') == '1';
        var valid = true;

        if($city.val().trim().length < 3 
            || (!fiasRegexp.test($city.data('cityId')) && !fiasRegexp.test($city.data('settlementId')))
        ) {
            $city.addClass('is-invalid').closest('.form-group').addClass('is-invalid');
            valid = false;
        } else {
            $city.removeClass('is-invalid').closest('.form-group').removeClass('is-invalid');
        }

        if($sel.length === 0) {
            $delivery.closest('.form-group').addClass('is-invalid');
            valid = false;
        } else {
            $delivery.closest('.form-group').removeClass('is-invalid');
        }

        if(pvzNeed && !$pvz.data('complete')) {
            $pvz.addClass('is-invalid').closest('.form-group').addClass('is-invalid');
            valid = false;
        } else {
            $pvz.removeClass('is-invalid').closest('.form-group').removeClass('is-invalid');
        }

        if(flatNeed) {
            if($street.val().trim().length < 3) {
                $street.addClass('is-invalid').closest('.form-group').addClass('is-invalid');
                valid = false;
            } else {
                $street.removeClass('is-invalid').closest('.form-group').removeClass('is-invalid');
            }

            if($house.val().trim().length < 1) {
                $house.addClass('is-invalid').closest('.form-group').addClass('is-invalid');
                valid = false;
            } else {
                $house.removeClass('is-invalid').closest('.form-group').removeClass('is-invalid');
            }

            if(!zipRegexp.test($postalCode.val().trim())) {
                $postalCode.addClass('is-invalid').closest('.form-group').addClass('is-invalid');
                valid = false;
            } else {
                $postalCode.removeClass('is-invalid').closest('.form-group').removeClass('is-invalid');
            }
        } else {
            $street.removeClass('is-invalid').closest('.form-group').removeClass('is-invalid');
            $house.removeClass('is-invalid').closest('.form-group').removeClass('is-invalid');
            $postalCode.removeClass('is-invalid').closest('.form-group').removeClass('is-invalid');
        }

        if(!phoneRegexp.test($phone.val().trim())) {
            $phone.addClass('is-invalid').closest('.form-group').addClass('is-invalid');
            valid = false;
        } else {
            $phone.removeClass('is-invalid').closest('.form-group').removeClass('is-invalid');
        }

        if($email.val().trim() && !emailRegexp.test($email.val().trim())) {
            $email.addClass('is-invalid').closest('.form-group').addClass('is-invalid');
            valid = false;
        } else {
            $email.removeClass('is-invalid').closest('.form-group').removeClass('is-invalid');
        }

        return valid;
    }
    //#endregion

    //#region Form events

    /**
     * Город изменён
     */
    function onChangeCity() {
        var $city = $('#inputCity');
        var fiasId = null;

        if ($city.val().trim()) {
            if (fiasRegexp.test($city.data('settlementId'))) fiasId = $city.data('settlementId');
            else if (fiasRegexp.test($city.data('cityId'))) fiasId = $city.data('cityId');
        }

        if (fiasId) { // Город выбран из списка
            if (zipRegexp.test($city.data('postalCode'))) { // Стандартизация не требуется
                showDelivery();
            } else { // Необходимо прояснить индекс
                clarifyCity();
            }
        } else {
            hideDelivery();
        }

        validate();
    }

    /**
     * Улица изменена
     */
    function onChangeStreet() {
        var $street = $('#inputStreet');
        var $house = $('#inputHouse');
        var $flat = $('#inputHouse');
        var $postalCode = $('inputPostalCode');

        if(fiasRegexp.test($street.data('streetId')) && fiasRegexp.test($house.data('houseId'))
            && $street.data('streetId') !== $house.data('houseId')) { // Был выбран дом по другой улице
                fillAddress($house, '');
                $flat.val('');
                $postalCode.val('');
            }

        fillPostalCode();
        validate();
    }

    /**
     * Дом изменен
     */
    function onChangeHouse() {
        fillPostalCode();
        validate();
    }

    /**
     * Пункт выдачи изменён
     */
    function onChangePvz() {
        validate();
    }

    function onReloadProducts() {
        var $form = $(this);
        var $productsRow = $('#orderHasProductsRow');
        var $emptyBasketRow = $('#orderNoProductsRow');
        $form.find('input[name="order"]').val($('#inputBasketProducts').val());

        if($productsRow.find('.basket-item-row').length > 0) {
            $emptyBasketRow.addClass('d-none');
            $productsRow.removeClass('d-none');
            showDelivery(true);
        } else {
            $emptyBasketRow.removeClass('d-none');
            $productsRow.addClass('d-none');
        }
    }
    //#endregion

    //#region Basket functions
    function updateBasket() {
        $.ajax({
            method: "GET",
            url: "/basket",
            dataType: "json"
        }).done(function (msg) {
            $('a.nav-link.basket label').text(msg.length);

            if (msg.length === 0)
                $('a.nav-link.basket').removeClass('has-products');
            else
                $('a.nav-link.basket').addClass('has-products');
        }).fail(function () {
        });
    }

    function orderPrepare() {
        var $form = $(this).closest('form');
        var $popup = $('#order-popup');

        $('#order-popup-back').show();
        $popup.css({ display: 'flex' });
        $popup.addClass('loading').removeClass('has-error').data('lock', '1');
        $popup.find('.order-preview').load('partial/order/prepare?' + $form.serialize() + ' .order-prepare', function(response, status, xhr) {
            $popup.removeClass('loading').data('lock', '0');

            if (status == "error") {
                $popup.addClass('has-error');
                return;
            }

            orderPopupResize();

            setTimeout(function() {
                $('#order-popup #cancelButton').on('click', closePopup);
            }, 250);
        });
    }

    function orderCreate() {
        var $form = $('#orderForm');
        var $popup = $('#order-popup');
        $popup.css({ display: 'flex' });
        $popup.addClass('loading').removeClass('has-error').data('lock', '1');
        $.ajax({
            method: 'post',
            url: "/order",
            dataType: "json",
            data: $form.serialize(),
            success: function (response) {
                location.href = '/order/' + response.uuid;
            },
            error: function (jqXHR, exception) {
                $popup.removeClass('loading').addClass('has-error').data('lock', '0');
            }
        });
        
    }

    function closePopup() {
        if($('#order-popup').data('lock') != '1' && $('#order-popup-confirm').data('lock') != '1') {
            $('#order-popup-back').hide();
            $('#order-popup').css({ display: 'none' });
            $('#order-popup-confirm').css({ display: 'none' });
            $('#order-popup .order-popup-inner .order-preview').html('');
            $('#order-popup-confirm, #order-popup').removeClass('has-error').removeClass('loading');
        }
    }

    function updateBasketPrice(deliveryPrice) {
        var price = parseInt($('#inputBasketPrice').val());
        var total = undefined;

        if(typeof deliveryPrice === 'number') {
            total = price + deliveryPrice;
            $('#basketDeliveryPrice').html(deliveryPrice + ' руб.');
            $('#basketTotalPrice').html(total + ' руб.');
        } else {
            $('#basketDeliveryPrice').html('Способ доставки не выбран');
            $('#basketTotalPrice').html('Способ доставки не выбран');
        }
    }

    $('#order-popup-back, #order-popup, #order-popup-confirm, #order-popup-confirm .btn.cancel-button, #order-popup .btn.cancel-button').on('click', closePopup);

    $('.order-popup-inner').on('click', function(e) {
        var $target = $(e.target);

        if($target.closest('button').attr('id') === 'orderButton') {
            orderCreate();
        }

        e.stopPropagation();
        return false;
    });

    var orderPopupResize = function() {
        var margin = parseInt($('#order-popup').css('padding-top'));
        if(isNaN(margin)) margin = 0;
        $('#order-popup-inner > div').css({ 'max-height': window.innerHeight - margin * 2 })
    }

    $(window).on('resize', orderPopupResize);

    // Клик на кнопку удаления из корзины открывает окно подтверждения
    $('.basket-item-row .btn[data-action="remove"]').on('click', function() {
        $('#order-popup-back').show();
        $('#order-popup-confirm').css({ display: 'flex' });
        $('#order-popup-confirm .btn.remove-button').data('id', $(this).data('id'));
    });

    // Клик на подтверждение удаления
    $('#order-popup-confirm .btn.remove-button').on('click', function() {
        var $btn = $(this);
        var $popup = $('#order-popup-confirm');

        $popup.removeClass('has-error').addClass('loading').data('lock', '1');

        removeProductReq($btn.data('id'), function(err) {
            if(err) {
                $popup.removeClass('loading').addClass('has-error').data('lock', '0');
                return;
            }

            $('#basket .products-row').load('/basket #basket .products-row .products-col', function(response, status, xhr) {
                $popup.removeClass('loading').data('lock', '0');

                if (status == "error") {
                    $popup.addClass('has-error');
                    return;
                }

                $('#orderForm').trigger('reloadproducts');
                updateBasket();
                closePopup();
                $('.basket-item-row .btn[data-action="remove"]').on('click', function() {
                    $('#order-popup-back').show();
                    $('#order-popup-confirm').css({ display: 'flex' });
                    $('#order-popup-confirm .btn.remove-button').data('id', $(this).data('id'));
                });
            });
        });
    });
    //#endregion

    //#region Set up event handlers
    $('#orderForm').on('submit', function(e) {
        e.preventDefault();
        e.stopPropagation();
        return false;
    });

    $('#inputCity')
        .on('focus', onFocusHandler)
        .on('blur', onBlurHandler);

    $('#inputStreet')
        .on('focus', onFocusHandler)
        .on('blur', onBlurHandler);

    $('#inputHouse')
        .on('focus', onFocusHandler)
        .on('blur', onBlurHandler);

    $('#inputPvz')
        .on('focus', customSelectFocus)
        .on('blur', customSelectBlur);

    $('#inputPostalCode')
        .on('keyup', function() { validate(); })
        .on('change', function() { validate(); });

    $('#inputPhone')
        .on('keyup', function() { validate(); })
        .on('change', function() { validate(); });

    $('#inputEmail')
        .on('keyup', function() { validate(); })
        .on('change', function() { validate(); });

    $('#orderForm')
        .on('changecity', onChangeCity)
        .on('changestreet', onChangeStreet)
        .on('changehouse', onChangeHouse)
        .on('changepvz', onChangePvz)
        .on('reloadproducts', onReloadProducts);

    $('#orderForm .form-group.delivery-list-holder a.retry-request').on('click', function(e) {
        onChangeCity();
        e.preventDefault();
        e.stopPropagation();
        return false;
    });

    $('#orderForm .form-group.pvz-input-holder a.retry-request').on('click', function(e) {
        onClickDeliveryMethod();
        e.preventDefault();
        e.stopPropagation();
        return false;
    });

    $('#orderForm button[type="submit"]').on('click', function() {
        $('#orderForm').addClass('submit');
        if(validate()) {
            orderPrepare.call(this);
        }
    });
    //#endregion
    
})(jQuery);