(function ($) {
    var popupElements = null;
    var orderInProcess = false;
    var resizedTimeout = null;
    var mouseOutTimeout = null;
    var closing = false;

    function loadProduct() {
        var query = getQueryVariables();
        var product = parseInt(query.product);

        if (isNaN(product) || product < 1) return;

        closing = false;
        $('#gallery-popup-back, #gallery-popup').addClass('popup-visible');
        $('#gallery-popup-inner').addClass('loading').load('/partial/product/' + product + ' .container > *', function (response, status, xhr) {
            if (closing) {
                closePopup();
                return;
            }

            if (status == "error") {
                $('#gallery-popup-inner').addClass('error');
                return;
            }

            var src = $('.gallery-popup .col-left .gallery-popup-image img').attr('src');
            var $imgs = $('.gallery-popup .col-left .gallery-popup-images img[data-src]');
            var img = new Image();

            img.onload = function () {
                setTimeout(function () {
                    $('#gallery-popup-inner').removeClass('loading');
                    initPopup();
                    popupPos();
                    initImageChanging();
                }, 500);

                setTimeout(function () {
                    popupPos();
                }, 900);

                setTimeout(function () {
                    popupPos();
                }, 1300);

                $imgs.each(function () {
                    var src = $(this).data('src');
                    if (src) {
                        var img = new Image();
                        img.src = src;
                    }
                });
            }

            img.src = src;
        });
    }

    function addToBasket() {
        if (orderInProcess) return;
        if (!validateForm()) return;

        var product = parseInt(getQueryVariables().product);
        var mat = popupElements.$form.find('input[name="material"]:checked').val();
        var size = popupElements.$form.find('input[name="size"]:checked').val();
        var paspartu = popupElements.$form.find('input[name="paspartu"]:checked').val();

        if (isNaN(product) || product < 1) return;

        orderInProcess = true;

        $.ajax({
            method: "POST",
            url: "/basket",
            dataType: "json",
            contentType: "application/json",
            data: JSON.stringify({
                productId: product,
                material: mat.toUpperCase(),
                size: size.toUpperCase(),
                paspartu: parseInt(paspartu)
            })
        }).done(function (msg) {
            var $info = $('#gallery-popup-info');
            $info.find('.text').hide();
            $info.find('.text[data-info-key="order"]').show();
            $info.find('.btn').hide();
            $info.find('.order-btn').css({ display: 'block' });
            $info.css({ display: 'flex' });
            updateBasket();
        }).fail(function () {
            var $info = $('#gallery-popup-info');
            $info.find('.text').hide();
            $info.find('.text[data-info-key="error"]').show();
            $info.find('.btn').hide();
            $info.find('.default-btn').show();
            $info.css({ display: 'flex' });
        }).always(function () {
            orderInProcess = false;
        });
    }

    function updateBasket() {
        $.ajax({
            method: "GET",
            url: "/basket",
            dataType: "json"
        }).done(function (msg) {
            $('a.nav-link.basket label').text(msg.length);

            if (msg.length === 0)
                $('a.nav-link.basket').removeClass('has-products');
            else
                $('a.nav-link.basket').addClass('has-products');
        }).fail(function () {
        });
    }

    function closePopup() {
        if (popupElements) {
            popupElements.$imgLeft.css({ 'max-height': '', 'max-width': '' });
            popupElements.$imgRight.css({ 'max-height': '', 'max-width': '' });
            popupElements.$imgLeft.parent().css({
                'flex-basis': '',
                'max-height': '',
                'max-width': '',
                'width': '',
                'height': ''
            });
            popupElements.$imgRight.parent().css({
                'flex-basis': '',
                'max-height': '',
                'max-width': '',
                'width': '',
                'height': ''
            });
            popupElements.$imgLeft.parent().css({
                'height': '',
            });
        }
        $('#gallery-popup-back, #gallery-popup, #gallery-popup-loader').removeClass('popup-visible');
        $('#gallery-popup-inner').removeClass('error');
        $('#gallery-popup-inner').html();
        popupElements = null;
        closing = true;
    }

    function validateForm() {
        var mat = popupElements.$form.find('input[name="material"]:checked').val();
        var size = popupElements.$form.find('input[name="size"]:checked').val();
        var result = true;

        if (!mat) {
            popupElements.$form.find('label[data-for="material"]').html(
                popupElements.$form.find('label[data-for="material"]').data('error')
                + '<span class="icon-help-circled-alt"></span>'
            ).addClass('error');
            result = false;
        } else {
            popupElements.$form.find('label[data-for="material"]').html(
                popupElements.$form.find('label[data-for="material"]').data('text')
                + '<span class="icon-help-circled-alt"></span>'
            ).removeClass('error');
        }

        if (!size) {
            popupElements.$form.find('label[data-for="size"]').html(
                popupElements.$form.find('label[data-for="size"]').data('error')
                + '<span class="icon-help-circled-alt"></span>'
            ).addClass('error');
            result = false;
        } else {
            popupElements.$form.find('label[data-for="size"]').html(
                popupElements.$form.find('label[data-for="size"]').data('text')
                + '<span class="icon-help-circled-alt"></span>'
            ).removeClass('error');
        }
        return result;
    }

    function calcPrice() {
        if (!popupElements) return;
        var mat = popupElements.$form.find('input:radio[name="material"]:checked').val();
        var size = popupElements.$form.find('input:radio[name="size"]:checked').val();
        var val = 0;
        var complete = false;

        if (!mat) {
            if (!size) {
                val = price.PRICE_PAPER_A3;
            } else {
                val = Math.min(price['PRICE_PAPER_' + size.toUpperCase()], price['PRICE_FOAM_' + size.toUpperCase()]);
            }
        } else {
            if (!size) {
                val = price['PRICE_' + mat.toUpperCase() + '_A3'];
            } else {
                val = price['PRICE_' + mat.toUpperCase() + '_' + size.toUpperCase()];
                complete = true;
            }
        }
        return [val, complete];
    }

    function setPrice() {
        if (!popupElements) return;

        var price = calcPrice();
        var str = '';

        if (!price[1])
            str = 'от ' + price[0];
        else
            str = price[0].toString();

        popupElements.$formBtnHolder.find('.gallery-popup-price').text(str + ' руб.');
    }

    function initPopup() {
        var $container = $('.gallery-popup-row');
        var $colRight = $container.find('.col-right');
        var $colLeft = $container.find('.col-left');
        var $title = $colRight.find('.gallery-popup-name');
        var $form = $colRight.find('.gallery-popup-form');
        var $imgsHolder = $colLeft.find('.gallery-popup-images');
        var $imgLeft = $colLeft.find('.gallery-popup-image > img');
        var $imgRight = $colRight.find('.gallery-popup-image > img');
        var $formFieldHolder = $form.find('> div');
        var $formBtnHolder = $form.find('> .field-row');
        var $descriptionHolder = $colRight.find('.gallery-popup-description');
        var $description = $descriptionHolder.find('> div').eq(0);
        var submitted = false;

        $container.find('span.icon-cancel').on('click', closePopup);
        $container.find('label[data-info-key]').on('click', function () {
            var $el = $(this);
            var $info = $('#gallery-popup-info');
            var key = $el.data('info-key');
            $info.find('.text').hide();
            $info.find('.text[data-info-key="' + key + '"]').show();
            $info.find('.btn').hide();
            $info.find('.default-btn').show();
            $info.css({ display: 'flex' });
        });

        $('#gallery-popup-info').find('.btn').on('click', function () {
            var $info = $(this).closest('#gallery-popup-info')
            $info.css({ display: 'none' });
        });

        $('#order-button').on('click', function () {
            submitted = true;
            if (validateForm()) {
                addToBasket();
            }
        });

        $form.find('input:radio').on('click', function () {
            var mat = popupElements.$form.find('input[name="material"]:checked').val();
            submitted && validateForm();
            setPrice();
            if(mat === 'foam') {
                popupElements.$form.find('input[name="paspartu"][value="0"]').prop('checked', true);
                popupElements.$form.find('input[name="paspartu"][value="5"]').parent().hide();
                popupElements.$form.find('input[name="paspartu"][value="10"]').parent().hide();
                popupElements.$form.find('input[name="paspartu"]')
                    .prop('disabled', true).closest('.field').addClass('disabled');
            } else {
                popupElements.$form.find('input[name="paspartu"][value="5"]').parent().show();
                popupElements.$form.find('input[name="paspartu"][value="10"]').parent().show();
                popupElements.$form.find('input[name="paspartu"]')
                    .prop('disabled', false).closest('.field').removeClass('disabled');;
            }
        });

        $imgsHolder.find('img[data-src]').each(function() {
            $(this).on('click', function() {
                $imgsHolder.find('img[data-src]').removeClass('active');
                $(this).addClass('active');
                $imgLeft.attr('src', $(this).data('src'));
            })
        });
            

        popupElements = {
            $container,
            $colRight,
            $colLeft,
            $title,
            $form,
            $imgsHolder,
            $imgRight,
            $imgLeft,
            $formFieldHolder,
            $formBtnHolder,
            $descriptionHolder,
            $description
        };

        setPrice();

        return popupElements;
    }

    function popupPos() {
        if (!popupElements) return;

        if (!resizedTimeout) {
            resizedTimeout = setTimeout(function () {
                resizedTimeout = null;
                var winWidth = window.innerWidth;
                if (winWidth < 736) popupPosSmall();
                else if (winWidth < 992) popupPosMedium();
                else popupPosMedium();
            }, 500);
        }
    }

    function popupPosSmall() {
        var winWidth = window.innerWidth;
        var winHeight = window.innerHeight;
        var imgWidth = parseInt(popupElements.$imgRight.parent().data('width'));
        var imgHeight = parseInt(popupElements.$imgRight.parent().data('height'));
        var imgMaxHeight = 0;
        var titleHeight = 0;
        var formHeight = 0;
        var popupHeight = 0;
        var padding = parseInt(popupElements.$colRight.css('padding-top'));
        var imgRatio = 1.415730337;

        if (isNaN(padding)) padding = 30;

        /*if (isNaN(imgWidth) || imgWidth <= 0
            || isNaN(imgHeight) || imgHeight <= 0) {
            imgMaxHeight = Math.floor(winWidth / 1.37);
        } else */
        if (imgWidth > imgHeight) {
            imgMaxHeight = Math.floor(winWidth / imgRatio);
        } else {
            imgMaxHeight = Math.floor(winWidth * imgRatio);
        }

        popupElements.$colRight.removeClass('show-description');

        formHeight = popupElements.$formFieldHolder.outerHeight(true)
            + popupElements.$formBtnHolder.outerHeight(true);
        titleHeight = popupElements.$title.outerHeight(true);
        popupHeight = formHeight + titleHeight + imgMaxHeight + padding * 2 + 15;

        if (popupHeight > winHeight) {
            imgMaxHeight = winHeight - (formHeight + titleHeight + padding * 2 + 15);
        }

        $('#gallery-popup').addClass('popup-fullscreen');

        popupElements.$imgRight.parent().css({
            'flex-basis': imgMaxHeight,
            'max-height': imgMaxHeight
        });
    }

    function popupPosMedium() {
        var winWidth = window.innerWidth;
        var winHeight = window.innerHeight;
        var imgWidth = parseInt(popupElements.$imgLeft.parent().data('width'));
        var imgHeight = parseInt(popupElements.$imgLeft.parent().data('height'));
        var imgMaxHeight = 630;
        var imgMaxWidth = 0;
        var titleHeight = 0;
        var formHeight = 0;
        var infoHeight = 0;
        var popupHeight = 0;
        var descHeight = 0;
        var padding = parseInt(popupElements.$colRight.css('padding-top'));
        var popupMargin = parseInt(popupElements.$container.parent().parent().css('padding-top'));
        var imgRatio = 1.415730337;
        var complete = false;
        var minInfoWidth = 340;
        var maxInfoWidth = 460;
        var fullscreenAllow = false;
        var imgSelectorVisible = popupElements.$imgsHolder.is(':visible');
        var imagesWidth = imgSelectorVisible ? popupElements.$imgsHolder.outerWidth(true) : 0;

        minInfoWidth += imagesWidth;
        maxInfoWidth += imagesWidth;

        if (isNaN(padding)) padding = 30;
        if (isNaN(popupMargin)) popupMargin = 15;

        if(winWidth < 1100) {
            var setSrc = popupElements.$imgsHolder.find("img").eq(0).data('src');

            if(popupElements.$imgLeft.attr('src') !== setSrc) {
                popupElements.$imgLeft.attr('src', setSrc);
                popupElements.$imgsHolder.find('img[data-src]').removeClass('active');
                popupElements.$imgsHolder.find('img[data-src]').eq(0).addClass('active');
            }
        }

        var box = {
            height: Math.min(imgMaxHeight, window.innerHeight - popupMargin * 2 - padding * 2),
            width: Math.min(1100, window.innerWidth) - popupMargin * 2 - padding * 2
        }

        fullscreenAllow = window.innerWidth < 1100;

        imgMaxWidth = Math.floor(imgMaxHeight / imgRatio);

        if(fullscreenAllow) { // Рассчёт для фулскрин
            if (minInfoWidth + imgMaxWidth + padding > winWidth) { // Уменьшаем зону изображения чтобы было минимум minInfoWidth под форму
                imgMaxWidth = winWidth - minInfoWidth - padding;
                imgMaxHeight = Math.floor(imgMaxWidth * imgRatio);
            }

            if (imgMaxHeight >= winHeight) { // Уменьшаем зону изображения если она больше экрана по высоте
                imgMaxHeight = winHeight;
                imgMaxWidth = Math.floor(imgMaxHeight / imgRatio);
                $('#gallery-popup').addClass('popup-fullscreen');
            } else {
                fullscreenAllow = false; // Красивого фуллскрина не намечается
            }
        }

        if (!fullscreenAllow) { // Не фулскрин, пересчитываем с учётом оступов
            $('#gallery-popup').removeClass('popup-fullscreen');

            imgMaxHeight = box.height;
            imgMaxWidth = Math.floor(imgMaxHeight / imgRatio);

            if (minInfoWidth + imgMaxWidth > box.width) {
                imgMaxWidth = box.width - minInfoWidth;
                imgMaxHeight = Math.floor(imgMaxWidth * imgRatio);
            }
        }

        if (imgSelectorVisible) {
            imgMaxWidth += imagesWidth;
        }

        popupElements.$colLeft.css({
            'width': imgMaxWidth,
            'max-width': imgMaxWidth,
            'flex-basis': imgMaxWidth
        });

        popupElements.$imgLeft.css({
            'max-width': '100%',
            'max-height': '',
        });

        popupElements.$imgLeft.parent().css({
            'height': imgMaxHeight,
        });

        imgMaxWidth -= parseInt(popupElements.$colLeft.css('padding-left'))
            + parseInt(popupElements.$colLeft.css('padding-right')) + imagesWidth;
        imgMaxHeight = Math.floor(imgMaxWidth * imgRatio);

        formHeight = popupElements.$formFieldHolder.outerHeight(true);
        formHeight += popupElements.$formBtnHolder.outerHeight(true);
        titleHeight = popupElements.$title.outerHeight(true);
        descHeight = popupElements.$description.outerHeight(true);

        infoHeight = formHeight + titleHeight + (fullscreenAllow ? padding * 2 : 0);

        if (infoHeight + descHeight < imgMaxHeight) {
            popupElements.$colRight.addClass('show-description');
        } else {
            popupElements.$colRight.removeClass('show-description');
        }
    }

    function free() {
        var $el = $(this);
        var $imgs = popupElements.$imgsHolder.find("img");
        var $pager = $el.find(".project-pager");
        $el.data('running', '0');
        $el.data('item', '-1');
        $el.off('mousemove', move);
        popupElements.$imgLeft.eq(0).attr('src', $imgs.eq(0).data('src'));
        $pager.remove();
    }

    function move(event) {
        var $el = $(this);
        var $imgs = popupElements.$imgsHolder.find("img");
        var rect = $el.get(0).getBoundingClientRect();
        var x = event.clientX - rect.left;
        var width = $el.width();
        var itemWidth = width / $imgs.length;
        var item = Math.ceil(x / itemWidth);

        if ($el.data('item') !== item.toString()) {
            $el.data('item', item.toString());
            showItem.call(this, item - 1);
        }
    }

    function showItem(index) {
        var $el = $(this);
        var $imgs = popupElements.$imgsHolder.find("img");
        var $pager = $el.find(".project-pager");
        $pager.find('div').removeClass('active');
        $pager.find('div').eq(index).addClass('active');
        popupElements.$imgLeft.eq(0).attr('src', $imgs.eq(index).data('src'));
    }

    function initImageChanging() {
        if(isMobile.any) return;

        popupElements.$imgLeft.parent().on("mouseover", function () {
            if(window.innerWidth < 768 || window.innerWidth >= 1100)
                return;

            var $el = $(this);

            if ($el.data("running") === '1') {
                clearTimeout(mouseOutTimeout);
                return;
            } else {
                $el.data('running', '1');
            }

            var $imgs = popupElements.$imgsHolder.find("img");
            var $pager = $('<div class="project-pager"></div>').appendTo($el);

            for (var i = 0; i < $imgs.length; i++) {
                $pager.append('<div></div>');
            }

            $pager.eq(0).addClass("active");

            if ($imgs.length > 1)
                $pager.show();
            else
                $pager.hide();

            $el.on('mousemove', move);
        }).on("mouseout", function () {
            var $el = $(this);
            if ($el.data("running") === '1') {
                mouseOutTimeout = setTimeout(function () {
                    free.call($el);
                }, 50);
            }
        });
    }

    $(window).resize(function () {
        popupPos();
    });

    $('.gallery').on('click', function (event) {
        var $el = $(event.target);
        var $project = $el.closest('.project');
        var isProject = $project.length === 1;
        var newState = {};

        if (isProject) {
            var id = parseInt($project.data('id'));
            if (isNaN(id)) isProject = false;
            else newState.product = id;
        } else {
            newState = null;
        }

        if (newState) {
            addOrReplaceUrlParams(newState);
            loadProduct();
        }

        if (isProject) {
            event.preventDefault();
            event.stopPropagation();
            return false;
        }
    });

    $('#gallery-popup-inner').on('click', function (e) {
        if ($(e.target).closest('.error-message').length === 1) {
            closePopup();
        }
    });

    $('#gallery-popup-inner').on('click', function(e) {
        e.stopPropagation();
    });

    $('#gallery-popup').on('click', function() {
        closePopup();
    });
})(jQuery);