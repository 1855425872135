(function ($) {
    "use strict";
    //$(window).stellar({ responsive: true, parallaxBackgrounds: true, parallaxElements: true, horizontalScrolling: false, hideDistantElements: false, scrollProperty: 'scroll' });
    var fullHeight = function () {
        $('.js-fullheight').css('height', window.innerHeight - 69);
        $(window).resize(function () {
            !isMobile.phone && $('.js-fullheight').css('height', window.innerHeight - 69);
        });
    };
    fullHeight();
    var loader = function () { setTimeout(function () { if ($('#ftco-loader').length > 0) { $('#ftco-loader').removeClass('show'); } }, 1); };
    loader();

    var topOwl;
    var carousel = function () {
        topOwl = $('.home-slider').owlCarousel({
            loop: true,
            autoplay: !isMobile.any,
            autoplayTimeout: !isMobile.any ? 7000 : 99999999,
            margin: 0,
            animateOut: isMobile.any ? false : 'fadeOut',
            animateIn: isMobile.any ? false : 'fadeIn',
            nav: false,
            dots: true,
            autoplayHoverPause: true,
            items: 1,
            mouseDrag: !isMobile.any,
            touchDrag: isMobile.any,
            navText: ["<span class='ion-md-arrow-back'></span>", "<span class='ion-chevron-right'></span>"]
        });

        var navBarScrolledTimeout = null;
        var gBarScrolled = false;
        var gBarSleep = false;

        $(window).scroll(function () {
            var $w = $(this), 
            st = $w.scrollTop(), 
            $body = $(document.body),
            $navbar = $('.ftco_navbar'), 
            $afterGallery = $('.my-how-section'),
            $sd = $('.js-scroll-wrap'),
            $galleryBar = $('.gallery-grid');

            if($galleryBar.length === 1) {
                if(st >= $galleryBar.offset().top - 150) {
                    !gBarSleep && $body.addClass('gallery-bar-sleep');
                    gBarSleep = true;
                } else {
                    $body.removeClass('gallery-bar-sleep');
                    gBarSleep = false;
                }

                if(st >= $galleryBar.offset().top - 150
                    && st < $afterGallery.offset().top - 250) {
                    !gBarScrolled && (navBarScrolledTimeout = setTimeout(function() {
                        $body.addClass('gallery-bar-scrolled'); 
                        gBarScrolled = true; 
                    }, 300));
                } else {
                    if(navBarScrolledTimeout) {
                        clearTimeout(navBarScrolledTimeout);
                        navBarScrolledTimeout = null;
                    }
                    gBarScrolled && $body.removeClass('gallery-bar-scrolled');
                    gBarScrolled = false;
                }
            }

            if (st > 150) {
                $navbar.addClass('scrolled');
                $body.addClass('navbar-scrolled');
                if(!($navbar.hasClass('awake'))) {
                    $('#ftco-nav').removeClass('show');
                    $('.navbar-toggler').removeClass('collapsed');
                }
            } else {
                $navbar.removeClass('scrolled sleep'); 
                $body.removeClass('navbar-scrolled');
            } 
            
            if (st > 350) {
                $navbar.addClass('awake');
                $sd.addClass('sleep');
            } else {
                if ($navbar.hasClass('awake')) { $navbar.removeClass('awake'); $navbar.addClass('sleep'); }
                if ($sd.length > 0) { $sd.removeClass('sleep'); }
            }
        });
    };
    carousel();
    // $.Scrollax(); 

    //scrollWindow(); 
    /*var counter = function () {
        $('#section-counter').waypoint(function (direction) {
            if (direction === 'down' && !$(this.element).hasClass('ftco-animated')) {
                var comma_separator_number_step = $.animateNumber.numberStepFactories.separator(',')
                $('.number').each(function () { var $this = $(this), num = $this.data('number'); console.log(num); $this.animateNumber({ number: num, numberStep: comma_separator_number_step }, 7000); });
            }
        }, { offset: '95%' });
    }
    counter(); */
    /*var contentWayPoint = function () {
        var i = 0; $('.ftco-animate').waypoint(function (direction) {
            if (direction === 'down' && !$(this.element).hasClass('ftco-animated')) {
                i++; $(this.element).addClass('item-animate'); setTimeout(function () {
                    $('body .ftco-animate.item-animate').each(function (k) {
                        var el = $(this); setTimeout(function () {
                            var effect = el.data('animate-effect'); if (effect === 'fadeIn') { el.addClass('fadeIn ftco-animated'); } else if (effect === 'fadeInLeft') { el.addClass('fadeInLeft ftco-animated'); } else if (effect === 'fadeInRight') { el.addClass('fadeInRight ftco-animated'); } else { el.addClass('fadeInUp ftco-animated'); }
                            el.removeClass('item-animate');
                        }, k * 50, 'easeInOutExpo');
                    });
                }, 100);
            }
        }, { offset: '95%' });
    }; */
    //contentWayPoint(); 
    var OnePageNav = function () {
        $(".slider-item a[href^='#'], #ftco-nav ul li a[href^='#']").on('click', function (e) {
            e.preventDefault();
            var hash = this.hash,
                $nav = $('.navbar-brand'),
                $navToggler = $('.navbar-toggler');

            if(!hash) {
                return;
            }

            if(hash === '#next') {
                topOwl.trigger('next.owl.carousel');
                return;
            }

            if ($navToggler.is(':visible')) {
                $navToggler.click();
            }

            $('html, body').animate({
                scrollTop: (hash === '#top' ? 0 : ($(hash).offset().top - $nav.outerHeight()))
            }, 700, 'easeInOutExpo', function () {
                setTimeout(function() { $(window).trigger('scroll');}, 10);
                setTimeout(function() { $(window).trigger('scroll');}, 250);
            });

        });
    };
    OnePageNav();

    $('.navbar-toggler').on('click', function() {
        var $nav = $('#ftco-nav');
        var $toggler = $(this);
        $toggler.toggleClass('collapsed');
        if($toggler.hasClass('collapsed')) 
            $nav.addClass('show');
        else
            $nav.removeClass('show');
    });

    $(window).trigger('scroll');
})(jQuery);