// UTILS
function getQueryVariables() {
    var retValue = {};
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        if(vars[i].indexOf('=') > -1) {
            var pair = vars[i].split('=');
            if(pair[1] !== 'undefined' && pair[1].length)
                retValue[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
        }
    }
    return retValue;
}

function addOrReplaceUrlParams(params) {
    var current = getQueryVariables();
    var keys = Object.keys(params);

    for (var i = 0; i < keys.length; i++) {
        if(typeof params[keys[i]] !== 'undefined')
            current[encodeURIComponent(keys[i])] = encodeURIComponent(params[keys[i]]);
        else {
            current[encodeURIComponent(keys[i])] = undefined;
            delete current[encodeURIComponent(keys[i])];
        }
    }

    var newUrl = getUrl(current);

    if(window.history && typeof window.history.replaceState === 'function')
        window.history.replaceState(current, '', newUrl);

    //return current;
    return newUrl;
}

function getUrl(params) {
    var current = typeof params === 'undefined' ? getQueryVariables() : params;
    var keys = Object.keys(current);
    var newUrl = [];

    for (var i = 0; i < keys.length; i++) {
        newUrl.push([keys[i], current[keys[i]]].join('='));
    }

    return [window.location.pathname, newUrl.join('&')].join('?');
}
// \END UTILS