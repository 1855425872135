(function ($) {
    var $galleryContainer = null;
    var $catsContainer = null;
    var mouseOutTimeout = 0;
    var loading = false;

    // LOAD FUNCTIONS
    function loadGallery() {
        if(loading) return;

        var $gallery = $('.gallery');
        var height = $gallery.outerHeight();
        console.log(height)
        $gallery.css({ height: height });
        $gallery.addClass('loading');
        loading = true;
        loaderPos();

        $gallery.load(getUrl() + ' .gallery > .container', function () {
            initGallery();
            initProducts();

            setTimeout(function() {
                $gallery.css({ height: '' });
                $gallery.removeClass('loading');
                loading = false;

                var $nav = $('.navbar-brand');

                try {
                    $('html, body').animate({
                        scrollTop: $('#galleryGridContainer').offset().top - 110
                    }, 700, 'easeInOutExpo', function () {
                        setTimeout(function() { $(window).trigger('scroll');}, 10);
                        setTimeout(function() { $(window).trigger('scroll');}, 250);
                    });
                } catch(err) {
                    console.error(err)
                }
            }, 1000);
        });
    }

    function like($product) {
        var $btn = $product.find('.btn-like');
        var id = parseInt($product.data('id'));
        var like = !$btn.hasClass('like-active');

        if(isNaN(id)) {
            return;
        }

        $.ajax({
            method: like ? "POST" : "DELETE",
            url: like ? "/like" : ("/like/" + id),
            dataType: "json",
            contentType: "application/json",
            data: JSON.stringify({
                productId: id
            })
        }).done(function (msg) {
            if(like)
                $btn.addClass('like-active');
            else
                $btn.removeClass('like-active');
        }).fail(function () {
            console.log(arguments)
        }).always(function() {
            
        });
    }
    // END LOAD FUNCTIONS


    function galleryCalc() {
        var gridOffset = 20;
        var width = $galleryContainer.width();
        var $row = $galleryContainer.find('.row');
        var $cols = $row.find('> div');
        var padding = parseInt($galleryContainer.css('padding-left'));
        var colsNumber = 3;

        if (window.innerWidth < 420)
            colsNumber = 1;
        else if (window.innerWidth < 768)
            colsNumber = 2;

        if (isNaN(padding))
            padding = 15;

        var imgWidth = (width - gridOffset * (colsNumber - 1)) / colsNumber;
        var imgHeightPortrait = Math.ceil(imgWidth * 1.437837838);

        if (imgHeightPortrait % 2 > 0)
            imgHeightPortrait -= imgHeightPortrait % 2;

        var imgHeightLand = (imgHeightPortrait - gridOffset) / 2;

        $galleryContainer.find('.project').each(function () {
            var $project = $(this);
            var $inner = $project.find('.project-inner');
            var landscape = $inner.hasClass('landscape');

            $inner.css({
                'max-width': Math.ceil(imgWidth),
                'max-height': landscape ? imgHeightLand : imgHeightPortrait
            });
        });

        if (colsNumber === 2) {
            var colHeight = [parseInt($cols.eq(0).data('height')), parseInt($cols.eq(1).data('height'))];

            if(isNaN(colHeight[0])) {
                colHeight[0] = 0;
                $cols.eq(0).find('.project').each(function () {
                    colHeight[0] += $(this).find('.project-inner').hasClass('landscape') ? 1 : 2;
                });
                $cols.eq(0).data('height', colHeight[0]);
            }

            if(isNaN(colHeight[1])) {
                colHeight[1] = 0;
                $cols.eq(1).find('.project').each(function () {
                    colHeight[1] += $(this).find('.project-inner').hasClass('landscape') ? 1 : 2;
                });
                $cols.eq(1).data('height', colHeight[1]);
            }

            var colIndex = colHeight[0] <= colHeight[1] ? 0 : 1;
            var order = 0;

            $cols.eq(2).find('.project-inner').each(function () {
                $(this).data('order', order++);
            });

            // Move from col:3 to col:1 and col:2
            $cols.eq(2).find('.project-inner:not(.landscape)').each(function () {
                var $project = $(this).parent();
                $project.addClass('backward');
                $cols.eq(colIndex).append($project);
                colHeight[colIndex] += 2;
                colIndex = colIndex === 1 ? 0 : 1;
            });

            colIndex = colHeight[0] <= colHeight[1] ? 0 : 1;
            
            // Move from col:3 to col:1 and col:2
            $cols.eq(2).find('.project-inner.landscape').each(function () {
                var $project = $(this).parent();
                $project.addClass('backward');
                $cols.eq(colIndex).append($project)
                colIndex = colIndex === 1 ? 0 : 1;
            });

            $cols.eq(2).hide();
        } else {
            var backward = [];

            $row.find('.backward').each(function() {
                var order = parseInt($(this).data('order'));
                if(isNaN(order)) return false;
                backward[order] = $(this);
            });

            for(var i = 0; i < backward.length; i++) {
                $cols.eq(2).append(backward[i])
            }

            $cols.eq(2).show();            
        }
    }

    function categoriesCalc() {
        var $cols = $catsContainer.find('.col-md-6');
        var width = $catsContainer.width();
        var w1 = 0;
        var w2 = $cols.eq(1).outerWidth(true);
        var storedWidth = parseInt($cols.eq(0).data('width'));

        if (isNaN(storedWidth) || storedWidth < 100) {
            $cols.eq(0).find('.category').children().each(function () {
                w1 += $(this).outerWidth(true);
            });
        } else {
            w1 = storedWidth;
        }

        if (width - 50 - w2 < w1) {
            $cols.eq(0).addClass('collapsed');
            $cols.eq(0).data('width', w1);
        } else {
            $cols.eq(0).removeClass('collapsed');
        }

    }

    function free() {
        var $el = $(this);
        var $imgs = $el.find("img");
        var $pager = $el.find(".project-pager").eq(0);

        $el.data('running', '0');
        $el.data('item', '-1');
        $el.off('mousemove', move);
        $imgs.eq(0).attr('src', $imgs.eq(0).data('back'));
        $pager.remove();
    }

    function move(event) {
        var $el = $(this);
        var $imgs = $el.find("img");
        var rect = $el.get(0).getBoundingClientRect();
        var x = event.clientX - rect.left;
        var width = $el.width();
        var itemWidth = width / $imgs.length;
        var item = Math.ceil(x / itemWidth);

        if ($el.data('item') !== item.toString()) {
            $el.data('item', item.toString());
            showItem.call(this, item - 1);
        }
    }

    function showItem(index) {
        var $el = $(this);
        var $imgs = $el.find("img");
        var $pager = $el.find(".project-pager");
        $pager.find('div').removeClass('active');
        $pager.find('div').eq(index).addClass('active');

        if (index > 0) {
            $el.removeClass("info");
            $imgs.eq(0).attr('src', $imgs.eq(index).attr('src'));
        } else if (index === 0) {
            $el.removeClass("info");
            $imgs.eq(0).attr('src', $imgs.eq(0).data('back'));
        }
    }

    function initProducts() {
        if(isMobile.any) {
            initProductsMobile();
        } else {
            initProductsDesktop();
        }

        $('.gallery .btn-like').on('click', function(e) {
            e.preventDefault();
            e.stopPropagation();
            like($(this).closest('.project'));
        })
    }

    function initProductsDesktop() {
        $(".project").on("mouseover", function () {
            var $el = $(this);

            if ($el.data("running") === '1') {
                clearTimeout(mouseOutTimeout);
                return;
            } else {
                $el.data('running', '1');
            }

            var $imgs = $el.find("img");
            var $pager = $('<div class="project-pager"></div>').appendTo($el.find(".project-inner"));

            $imgs.eq(0).data('back', $imgs.eq(0).attr("src"));

            for (var i = 0; i < $imgs.length; i++) {
                $pager.append('<div></div>');
            }

            $pager.eq(0).addClass("active");

            if ($imgs.length > 1)
                $pager.show();
            else
                $pager.hide();

            $el.on('mousemove', move);
        }).on("mouseout", function () {
            var $el = $(this);

            mouseOutTimeout = setTimeout(function () {
                free.call($el);
            }, 50);
        });
    }

    function initGallery() {
        $galleryContainer = $('.gallery > .container:eq(1)');
        $catsContainer = $('.gallery > .container:eq(0)');

        $catsContainer.find('.col-md-6').eq(0).find('.category').click(function () {
            $(this).toggleClass('expanded');
        });

        $catsContainer.find('.col-md-6').eq(1).find('.pager > .btn-page-selector').click(function (e) {
            $(this).toggleClass('expanded');
        });

        galleryCalc();
        categoriesCalc();
    }

    function initProductsMobile() {
        $('.project-inner').owlCarousel({
            loop: false,
            autoplay: false,
            autoplayTimeout: 99999999,
            margin: 0,
            animateOut: null,
            animateIn: null,
            nav: false,
            dots: true,
            autoplayHoverPause: true,
            items: 1,
            mouseDrag: false,
            touchDrag: true,
        });
    }

    $('.gallery').on('click', function (event) {
        if(loading) {
            event.preventDefault();
            event.stopPropagation();
            return false;
        }

        var $el = $(event.target);
        var $btn = $el.closest('a');
        var isPager = $btn.closest('.pager').length === 1;
        var isCat = $btn.closest('.category').length === 1;
        var newState = {};

        if (isPager) {
            var page = parseInt($btn.data('page'));
            if (isNaN(page)) isPager = false;
            else {
                newState.page = page;
                newState.product = undefined;
            }
        } else if (isCat) {
            var category = parseInt($btn.data('category'));
            if (isNaN(category) || category < 1) category = undefined;
            if (!category && $btn.data('category') === 'like') category = 'like';

            newState.category = category;
            newState.page = 1;
            newState.product = undefined;
        } 
        
        if(!isPager && !isCat) {
            newState = null;
        }

        if (newState) {
            addOrReplaceUrlParams(newState);
            loadGallery();
            event.preventDefault();
            event.stopPropagation();
            return false;
        }
    });

    function loaderPos() {
        var $w = $(window), 
        st = $w.scrollTop(),
        $gridCont = $('.gallery-grid-container'),
        $grid = $gridCont.find('.gallery-grid'),
        $loader = $gridCont.find('.circular'),
        gt = $grid.offset().top,
        gh = $grid.outerHeight();
        var top = 0;

        if(st > gt || st < gt + gh) {
            top = st + window.innerHeight / 2 - gt;
            top = Math.min(top, gh - window.innerHeight / 3);
            top = Math.max(top, window.innerHeight / 3);

            $loader.css({
                top: top
            });
        }
    }

    initGallery();
    initProducts();

    $(window).resize(function () {
        galleryCalc();
        categoriesCalc();
    });

    $(window).scroll(function () {
        if(!loading) return;
        loaderPos();
    });
})(jQuery);